import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class WhatsappService {
  constructor(private http: HttpClient) {}

  getStatus(): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path + `core/company-settings/whatsapp/get/`,
      options
    );
  }

  create(): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.post<any>(
      AppConfig.path + `core/company-settings/whatsapp/`,
      options
    );
  }

  sendMessage(text, number): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.post<any>(
      AppConfig.path + `core/company-settings/whatsapp/send/`,
      { text, number },
      options
    );
  }
}
