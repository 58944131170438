import { DatePipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { UserService } from "src/app/service/user.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { CompanyService } from "src/app/service/company.service";
import { MedicationService } from "src/app/service/medication.service";
import * as moment from "moment-timezone";

const ENDDATE = `01/01/2300`;
@Component({
  selector: "add-exam-clinic",
  templateUrl: "./add-exam-clinic.component.html",
  styleUrls: ["./add-exam-clinic.component.css"],
})
export class AddExamClinicComponent {
  constructor(
    private modalService: MDBModalService,
    private userService: UserService,
    private companyService: CompanyService,
    private datePipe: DatePipe
  ) {}

  modalRef2: MDBModalRef;
  @Input() patient_id;
  @Input() service_id;
  btn_text: string;
  is_new: boolean = true;

  type: string | number;
  dateExam: string;
  hourExam: string;
  title: string;
  titleData: string;
  observation: string;
  doctor: string;
  examTypes: {
    id: number;
    name: string;
  }[] = [];
  file: File;
  validator = new ValidatorUtils();

  checkbox_seg: boolean = true;
  checkbox_ter: boolean = true;
  checkbox_qua: boolean = true;
  checkbox_qui: boolean = true;
  checkbox_sex: boolean = true;
  checkbox_sab: boolean = true;
  checkbox_dom: boolean = true;

  selected_columns = ["seg", "ter", "qua", "qui", "sex", "sab", "dom"];
  @Input() saveAll: Subject<boolean>;
  lastSaved = null;

  ngOnInit() {
    this.getTypeExam();
    this.dateExam = moment(new Date()).format("DD/MM/YYYY");
    this.hourExam = moment(new Date()).format("hh:mm");

    this.loadFields();
    this.saveAll.subscribe((v) => {
      this.createMedication(true);
    });
  }

  loadFields() {
    const x = localStorage.getItem("serviceRunning");
    if (x) {
      const service = JSON.parse(x);
      if (service.exam) {
        this.dateExam = moment(new Date(service.exam.date_time)).format(
          "DD/MM/YYYY"
        );
        this.hourExam = moment(new Date(service.exam.date_time)).format(
          "hh:mm"
        );
        this.observation = service.exam.observation;
        this.titleData = service.exam.title;
        this.doctor = service.exam.doctor_responsible;
        this.type = service.exam.type_exam;
      }
    }
  }

  getTypeExam() {
    this.userService.getTypeExams().subscribe(
      (data) => {
        this.examTypes = [];
        for (const d of data) {
          this.examTypes.push({ id: d.id, name: d.name });
        }
      },
      (err) => {
        this.modalRef2.hide();
        this.error(err, "criar");
      }
    );
  }

  async handleFileInput(files: FileList) {
    if (files && files.length > 0) {
      this.file = files.item(0);
    }
  }

  blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  async insertExam(fromAutomatic = false) {
    if (!fromAutomatic) this.openMessage("Por favor, aguarde", "", true);

    const auxDate = this.dateExam.split("/");
    const data = {
      title: this.titleData,
      type_exam: this.type,
      patient: this.patient_id,
      doctor_responsible: this.doctor,
      date_time: `${auxDate[2]}-${auxDate[1]}-${auxDate[0]}T${this.hourExam}:00`,
      observation: this.observation,
      is_created_by_a_doctor: true,
    };
    if (this.file) {
      if (this.file.type === "application/pdf") {
        data["file"] = await this.blobToBase64(this.file);
      } else {
        data["image"] = await this.blobToBase64(this.file);
      }
    }

    if (fromAutomatic && JSON.stringify(this.lastSaved) === JSON.stringify(data)) {
      return;
    }
    //console.log(data);
    this.userService.insertExam(data, this.service_id).subscribe(
      (data) => {
        if (!fromAutomatic) this.modalRef2.hide();
        if (!fromAutomatic) this.openMessage("Sucesso!", "Exame criado", false);
        this.lastSaved = data;
        // this.action.next("update");
        // this.modalRef.hide();
      },
      (error) => {
        if (!fromAutomatic) this.modalRef2.hide();
        if (!fromAutomatic) this.error(error, "criar");
      }
    );
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(`Erro, não foi adicionar o exame.`, msg, false);
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "modal-lg",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  validateDate(date: string) {
    if (!date || date.length !== 10) {
      this.openMessage("Por favor,", "insira uma data válida", false);
      return false;
    }
    var day = Number(date.substring(0, 2));
    var month = Number(date.substring(3, 5));
    var year = Number(date.substring(6, 10));

    if (day > 31 || day < 1 || month > 12 || month < 1 || year < 1900) {
      this.openMessage("Por favor,", "insira uma data válida", false);
      return false;
    }
    return true;
  }
  createMedication(fromAutomatic = false) {
    if (this.validator.isEmptyOrNull(this.titleData)) {
      if (!fromAutomatic)
        this.openMessage("Por favor,", "Informe o título do exame.", false);
      return false;
    } else if (
      !this.type ||
      this.validator.isEmptyOrNull(this.type.toString())
    ) {
      if (!fromAutomatic)
        this.openMessage("Por favor,", "Informe a tipo do exame.", false);
      return false;
    } else if (this.validator.isEmptyOrNull(this.dateExam)) {
      if (!fromAutomatic)
        this.openMessage("Por favor,", "Informe a data do exame.", false);
      return false;
    } else if (this.validator.isEmptyOrNull(this.hourExam)) {
      if (!fromAutomatic)
        this.openMessage("Por favor,", "Informe a hora do exame.", false);
      return false;
    } else if (this.validator.isEmptyOrNull(this.doctor)) {
      if (!fromAutomatic)
        this.openMessage("Por favor,", "Informe o médico responsável.", false);
      return false;
    } else {
      if (
        !this.validateDate(this.dateExam) ||
        !this.validateHour(this.hourExam)
      ) {
        return false;
      }
    }

    this.insertExam(fromAutomatic);
  }

  validateHour(hour) {
    if (!hour || hour.length !== 5) {
      this.openMessage("Por favor,", "insira uma hora válida", false);
      return false;
    }
    var hora = Number(hour.substring(0, 2));
    var minuto = Number(hour.substring(3, 5));
    if (hora >= 24 || hora < 0 || minuto > 59 || minuto < 0) {
      this.openMessage("Por favor,", "insira uma hora válida", false);
      return false;
    }
    return true;
  }
}
