import { DatePipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { UserService } from "src/app/service/user.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { CompanyService } from "src/app/service/company.service";
import * as moment from "moment-timezone";

@Component({
  selector: "add-record-clinic",
  templateUrl: "./add-record-clinic.component.html",
  styleUrls: ["./add-record-clinic.component.css"],
})
export class AddRecordClinicComponent {
  constructor(
    private modalService: MDBModalService,
    private userService: UserService,
    private companyService: CompanyService,
    private datePipe: DatePipe
  ) {}

  modalRef2: MDBModalRef;
  @Input() patient_id: string;
  @Input() service_id: string;
  @Input() saveAll: Subject<boolean>;

  title: string = "";
  btn_text: string;
  is_new: boolean = true;

  dateRecord: string = "";
  hourRecord: string = "";
  observation: string = "";

  dia: string;
  sis: string;
  bpm: string;
  temp: string;
  glucose: string;
  saturation: string;
  weight: string;
  respiratoryFrequency: string;
  validator = new ValidatorUtils();
  lastSaved = null;

  ngOnInit() {
    this.dateRecord = moment(new Date()).format("DD/MM/YYYY");
    this.hourRecord = moment(new Date()).format("hh:mm");

    this.loadFields();

    this.saveAll.subscribe((v) => {
      this.createRecord(true);
    });
  }

  loadFields() {
    const x = localStorage.getItem("serviceRunning");
    if (x) {
      const service = JSON.parse(x);
      if (service.health_record) {
        this.dateRecord = moment(
          new Date(service.health_record.created_at)
        ).format("DD/MM/YYYY");
        this.hourRecord = moment(
          new Date(service.health_record.created_at)
        ).format("hh:mm");
        this.observation = service.health_record.observation;
        this.sis = service.health_record.blood_pressure_1;
        this.dia = service.health_record.blood_pressure_2;
        this.bpm = service.health_record.heartbeat;
        this.temp = service.health_record.temperature;
        this.glucose = service.health_record.glucose;
        this.saturation = service.health_record.oxygenation;
        this.weight = service.health_record.weight;
        this.respiratoryFrequency = service.health_record.respiratoryFrequency;
      }
    }
  }

  getPatientForm() {
    const aux = this.dateRecord.split("/");
    const time = `${aux[2]}-${aux[1]}-${aux[0]}T${this.hourRecord}:00`;
    let body = {
      type_data: "Manual",
      receipt_time: time,
      message: this.observation,
      heartbeat: this.bpm,
      blood_pressure_1: this.sis,
      blood_pressure_2: this.dia,
      temperature: this.temp,
      oxygenation: this.saturation,
      glucose: this.glucose,
      weight: this.weight,
      respiratoryFrequency: this.respiratoryFrequency,
    };
    if (body.message == null || body.message.length === 0) {
      delete body.message;
    }
    if (body.heartbeat == null || body.heartbeat.length === 0) {
      delete body.heartbeat;
    }
    if (body.blood_pressure_1 == null || body.blood_pressure_1.length === 0) {
      delete body.blood_pressure_1;
    }
    if (body.blood_pressure_2 == null || body.blood_pressure_2.length === 0) {
      delete body.blood_pressure_2;
    }
    if (body.temperature == null || body.temperature.length === 0) {
      delete body.temperature;
    }
    if (body.oxygenation == null || body.oxygenation.length === 0) {
      delete body.oxygenation;
    }
    if (body.glucose == null || body.glucose.length === 0) {
      delete body.glucose;
    }
    if (body.weight == null || body.weight.length === 0) {
      delete body.weight;
    }
    if (
      body.respiratoryFrequency == null ||
      body.respiratoryFrequency.length === 0
    ) {
      delete body.respiratoryFrequency;
    }
    return body;
  }

  insertRecord(fromAutomatic = false) {
    if (!fromAutomatic) this.openMessage("Por favor, aguarde", "", true);
    var body = this.getPatientForm();

    if (fromAutomatic) {
      if (
        !body.message &&
        !body.heartbeat &&
        !body.blood_pressure_1 &&
        !body.blood_pressure_2 &&
        !body.temperature &&
        !body.oxygenation &&
        !body.glucose &&
        !body.weight &&
        !body.respiratoryFrequency
      ) {
        return;
      }
    }
    if (fromAutomatic && JSON.stringify(this.lastSaved)==JSON.stringify(body)) {
      return;
    }
    
    this.userService
      .createRecord(body, this.patient_id, this.service_id)
      .subscribe(
        (data) => {
          this.lastSaved = body;
          if (!fromAutomatic) this.modalRef2.hide();
          if (!fromAutomatic)
            this.openMessage("Sucesso!", "Registro criado", false);
          // this.action.next("update");
          // this.modalRef.hide();
        },
        (error) => {
          if (!fromAutomatic) this.modalRef2.hide();
          if (!fromAutomatic) this.error(error, "criar");
        }
      );
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(`Erro, não foi adicionar a medição.`, msg, false);
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "modal-lg",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  createRecord(fromAutomatic = false) {
    console.log(this.patient_id);
    if (this.validator.isEmptyOrNull(this.dateRecord)) {
      if (!fromAutomatic)
        this.openMessage("Por favor,", "insira a data da medição.", false);
      return false;
    } else if (this.validator.isEmptyOrNull(this.hourRecord)) {
      if (!fromAutomatic)
        this.openMessage("Por favor,", "insira a hora da medição.", false);
      return false;
    } else {
      var day = Number(this.dateRecord.substring(0, 2));
      var month = Number(this.dateRecord.substring(3, 5));
      var year = Number(this.dateRecord.substring(6, 10));

      if (day > 31 || day < 1 || month > 12 || month < 1 || year < 1900) {
        if (!fromAutomatic)
          this.openMessage(
            "Por favor,",
            "insira uma data de medição válida",
            false
          );
        return false;
      }
      var hora = Number(this.hourRecord.substring(0, 2));
      var minuto = Number(this.hourRecord.substring(3, 5));
      if (hora >= 24 || hora < 0 || minuto > 59 || minuto < 0) {
        if (!fromAutomatic)
          this.openMessage(
            "Por favor,",
            "insira uma hora de medição válida",
            false
          );
        return false;
      }
    }
    this.insertRecord(fromAutomatic);
  }
}
