import { DatePipe } from "@angular/common";
import { Component, Input, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { ModalAddExamComponent } from "src/app/modals/modal-add-exam/modal-add-exam.component";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { UserService } from "src/app/service/user.service";

@Component({
  selector: "app-prontuario-completo",
  templateUrl: "./prontuario-completo.component.html",
  styleUrls: ["./prontuario-completo.component.css"],
})
export class ProntuarioCompletoComponent {
  constructor(
    private userService: UserService,
    private datePipe: DatePipe,
    private router: Router,
    private modalService: MDBModalService
  ) {}

  modalRef: MDBModalRef;

  @Input() patient_id: number;
  @Input() history: boolean;
  @Input() isService: boolean = false;

  is_loaded: boolean = false;
  noData = false;
  loadingTable: boolean = true;
  displayedColumns: string[] = [
    "date_time",
    "title",
    "observation",
    "type_exam_obj",
    "document",
  ];
  dataSource = [];

  aux_info = {};
  keys = [
    "date_time",
    "title",
    "observation",
    "type_exam_obj",
    "file",
    "image",
  ];

  loadingLinkPrescription = null;
  currentPage = 1;
  checkbox_health: boolean = true;
  checkbox_medication: boolean = true;
  checkbox_forms: boolean = true;
  checkbox_exam: boolean = true;
  checkbox_prescription: boolean = true;
  checkbox_intercorrencia: boolean = true;

  selected_columns = [
    "health",
    "medication",
    "forms",
    "exam",
    "prescription",
    "intercorrencia",
  ];

  ngOnInit() {
    if (localStorage.getItem("token") == null) {
      this.router.navigate([""]);
    } else {
      this.getData(1);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.patient_id = changes["patient_id"].currentValue;
    this.getData(1);
  }

  tamTable() {
    // var styles = {};
    // var list = this.selected_columns;
    // var conta = list.length * 180 + 100;
    // var tamanho = conta.toString() + "px";
    // styles["min-width"] = tamanho;
    // return styles;
  }

  clear() {
    this.dataSource = [];
    this.aux_info = {};
    this.noData = false;
  }

  getData(page) {
    this.loadingTable = true;
    this.is_loaded = false;

    if (page == 1) this.clear();

    this.userService
      .getProntuario(page, this.patient_id, this.selected_columns)
      .subscribe((data) => {
        console.log(data);
        for (let i = 0; i < data.length; i++) {
          if (data[i].type === "core_healthrecord")
            data[i].data = this.cleanHealth(data[i].data);
          else if (data[i].type === "core_myexam")
            data[i].data = this.adjustExam(data[i].data);
          else if (data[i].type === "core_formanswer") {
            const titles = {};

            for (const d of data[i].data) {
              if (!titles[d.form_question.id]) {
                titles[d.form_question.id] = {
                  name: d.form_question.title,
                  rows: { is_image: d.form_question.is_image, answer: "" },
                };
              }
              if (titles[d.form_question.id].rows.answer.length > 0) {
                titles[d.form_question.id].rows.answer += "<br/>";
              }
              titles[d.form_question.id].rows.answer += d.answer;
            }
            const allTitles = [];
            const ans = [];
            for (const d in titles) {
              allTitles.push(titles[d].name);
              ans.push(titles[d].rows);
            }
            data[i].titles = allTitles;
            data[i].rows = ans;
          }
        }

        if (this.currentPage === 1) {
          this.dataSource = data;
        } else {
          console.log("Concaet", this.dataSource.length);
          this.dataSource = [...this.dataSource, ...data];
          console.log("Concaet222", this.dataSource.length);
          if (data.length === 0) {
            this.noData = true;
          }
        }

        this.is_loaded = true;
        this.loadingTable = false;
      });
  }

  seeMore() {
    this.currentPage++;
    this.getData(this.currentPage);
  }

  openImageFull(url) {
    window.open(url, "_blank");
  }

  cleanHealth(element) {
    const keys = [
      "blood_pressure_1",
      "blood_pressure_2",
      "glucose",
      "temperature",
      "heartbeat",
      "oxygenation",
      "message",
      "receipt_time",
      "respiratoryFrequency",
      "createdBy",
    ];
    const record = element.record;
    const notification = element.notification;
    const key_time = this.datePipe.transform(
      new Date(record.receipt_time),
      "dd/MM/yyyy HH:mm"
    );

    this.aux_info[key_time] = {
      blood_pressure_1: [],
      blood_pressure_2: [],
      glucose: [],
      temperature: [],
      heartbeat: [],
      oxygenation: [],
      respiratoryFrequency: [],
      message: [],
      receipt_time: "",
      msg_notification: {
        blood_pressure_1: "",
        blood_pressure_2: "",
        glucose: "",
        temperature: "",
        heartbeat: "",
        oxygenation: "",
        respiratoryFrequency: "",
      },
      has_notification: {
        blood_pressure_1: [],
        blood_pressure_2: [],
        glucose: [],
        temperature: [],
        heartbeat: [],
        oxygenation: [],
        respiratoryFrequency: [],
      },
      createdBy: [],
    };

    keys.forEach((key) => {
      if (key == "receipt_time") {
        this.aux_info[key_time][key] = new Date(record[key]);
      } else if (key === "createdBy") {
        if (element["doctor"]) {
          this.aux_info[key_time][key].push(element.doctor.name);
        } else {
          this.aux_info[key_time][key].push("Paciente");
        }
      } else if (record[key] != null && record[key] != "") {
        this.aux_info[key_time][key].push(record[key]);
      }
    });

    if (notification && notification.length > 0) {
      for (let i = 0; i < notification.length; i += 1) {
        const not = notification[i];
        this.aux_info[key_time].has_notification[not.type_parameter].push(not);
        if (
          this.aux_info[key_time].msg_notification[not.type_parameter] === ""
        ) {
          this.aux_info[key_time].msg_notification[not.type_parameter] += `
									Alertas Disparados
								`;
        } else {
          this.aux_info[key_time].msg_notification[not.type_parameter] += `
								`;
        }
        const not_date = this.datePipe.transform(
          new Date(not.created_at),
          "dd/MM/yyyy HH:mm"
        );
        this.aux_info[key_time].msg_notification[not.type_parameter] += `
								Data: ${not_date}
								Leitura: ${not.value}
								Minimo / Maximo: ${not.min} / ${not.max}
							`;
      }
    }
    return this.aux_info[key_time];
  }

  adjustExam(element) {
    const key_time = this.datePipe.transform(
      new Date(element.date_time),
      "dd/MM/yyyy HH:mm"
    );
    const keys = [
      "date_time",
      "title",
      "observation",
      "type_exam_obj",
      "file",
      "image",
    ];

    this.aux_info[key_time] = {
      blood_pressure_1: [],
      blood_pressure_2: [],
      glucose: [],
      temperature: [],
      heartbeat: [],
      oxygenation: [],
      message: [],
      date_time: "",
      title: [],
      observation: [],
      type_exam_obj: [],
      file: [],
      image: [],
    };

    keys.forEach((key) => {
      if (key == "date_time") {
        this.aux_info[key_time][key] = new Date(element[key]);
      } else if (
        element[key] != null &&
        element[key] != "" &&
        !this.aux_info[key_time][key].includes(element[key])
      ) {
        this.aux_info[key_time][key].push(element[key]);
      }
    });

    return this.aux_info[key_time];
  }

  isContinous(data) {
    return data === "2300-01-01T00:00:00";
  }

  twoplaces(d) {
    if (d < 10) return "0" + d;
    return d;
  }

  getDays(days) {
    const arr = [
      "Domingo",
      "Segunda",
      "Terça",
      "Quarta",
      "Quinta",
      "Sexta",
      "Sábado",
    ];
    let ans = "";
    for (const i in days) {
      if (ans.length) ans += ", ";
      ans += arr[days[i]];
    }
    return ans;
  }

  getHours(hours) {
    let ans = "";

    for (const i in hours) {
      const h = hours[i];
      if (ans.length) ans += ", ";
      ans += this.twoplaces(Math.floor(h / 60)) + ":" + this.twoplaces(h % 60);
    }
    return ans;
  }

  getPrescriptionLink(token, id, pdf = false) {
    this.loadingLinkPrescription = id;
    if (pdf) {
      fetch(
        `https://api.memed.com.br/v1/prescricoes/${id}/url-document/full?token=${token}`,
        { method: "GET" }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.loadingLinkPrescription = null;
          if (
            data.data &&
            data.data.length &&
            data.data[0].attributes &&
            data.data[0].attributes.link
          ) {
            window.open(data.data[0].attributes.link, "_blank");
          } else {
            throw Error();
          }
        })
        .catch(() => {
          this.loadingLinkPrescription = null;
          this.openMessage(
            "Erro",
            "Ocorreu um erro para gerar o link da prescrição",
            false
          );
        });
    } else {
      fetch(
        `https://api.memed.com.br/v1/prescricoes/${id}/get-digital-prescription-link?token=${token}`,
        { method: "GET" }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);
          if (
            data.data &&
            data.data.length &&
            data.data[0].attributes &&
            data.data[0].attributes.link
          ) {
            window.open(data.data[0].attributes.link, "_blank");
          } else {
            throw Error();
          }
          this.loadingLinkPrescription = null;
        })
        .catch(() => {
          this.loadingLinkPrescription = null;
          this.openMessage(
            "Erro",
            "Ocorreu um erro para gerar o link da prescrição",
            false
          );
        });
    }
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  changeColumns() {
    this.currentPage = 1;
    this.getData(1);
  }
  getNameOfService(type, data) {
    switch (type) {
      case "core_healthrecord":
        return "Sinal vital";
      case "core_formanswer":
        return (
          "Formulário" +
          (data && data.length ? " - " + data[0].form_answer.form.title : "")
        );
      case "core_myexam":
        return "Exame";
      case "core_patientprescription":
        return "Prescrição médica";
      case "core_medicationpatient":
        return "Medicamento";
      case "core_intercorrencia":
        return "Intercorrencias";
    }
  }
}
