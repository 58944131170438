import { Component, OnInit } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Md5 } from "md5-typescript";
import { ModalChoiceComponent } from "src/app/modals/modal-choice/modal-choice.component";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { AdminService } from "src/app/service/admin.service";
import { CompanyService } from "src/app/service/company.service";
import { ProcedureService } from "src/app/service/procedure.service";
import { WhatsappService } from "src/app/service/whatsapp.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";

@Component({
  selector: "app-whatsapp-settings",
  templateUrl: "./whatsapp-settings.component.html",
  styleUrls: ["./whatsapp-settings.component.css"],
})
export class WhatsappSettingsComponent implements OnInit {
  modalRef: MDBModalRef;
  loading: boolean = false;
  data: any[];

  connected = false;
  loadingCreating = false;
  qrcode = null
  constructor(
    private modalService: MDBModalService,
    private companyService: CompanyService,
    private adminService: AdminService,
    private whatsappService: WhatsappService
  ) {}

  ngOnInit(): void {
    this.getWhatsapp();
    setInterval(()=> { this.getWhatsapp(true) }, 5 * 1000);
  }

  createData(result) {
    return {
      name: result.name,
      time: result.time,
      color: result.color,
      id: result.id,
    };
  }

  getWhatsapp(hideLoading = false) {
    if(!hideLoading)
      this.loading = true;
    this.whatsappService.getStatus().subscribe((data) => {
      console.log(data);
      this.connected = true;
      this.loading = false;
    }, (err) => {
      this.loading = false;
      this.connected = false;
    });
  }

  connect() {
    this.loadingCreating = true;
    this.whatsappService.create().subscribe((data) => {
      console.log(data);
      this.qrcode = data.qrcode;
      this.loadingCreating = false;
    }, (err) => {
      this.loadingCreating = false;
      this.openMessage('Erro', 'Ocorreu um erro ao conectar no whatsapp', false)
    });
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(
      `Erro, não foi possível ${string} o formulário.`,
      msg,
      false
    );
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }
}
